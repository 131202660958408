<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../assets/img/logo.png" class="rounded mx-auto d-block nav-img" >
        </b-navbar>
        <b-container class="content-section" v-show="showDashboard">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <b-row>
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>Pending Order</p>
                            <p>{{dashboard.pending}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>Confirmed Order</p>
                            <p>{{dashboard.confirmed}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>Shipping Order</p>
                            <p>{{dashboard.shipping}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>Completed Order</p>
                            <p>{{dashboard.complete}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>Today Order</p>
                            <p>{{dashboard.today}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card align="center">
                        <b-card-text>
                            <p>Purchase Order</p>
                            <p>{{dashboard.porder}}</p>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <h3>Return Item</h3>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <b-list-group @click="remainInfo()">
                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                            Remain
                            <b-badge variant="primary" pill>{{dashboard.remainReturn}}</b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
                <b-col>
                    <router-link to="/return-list">
                        <b-list-group>
                            <b-list-group-item class="d-flex justify-content-between align-items-center ">
                                <span class="text-dark">Completed</span>
                                <b-badge variant="primary" pill>{{dashboard.completeReturn}}</b-badge>
                            </b-list-group-item>
                        </b-list-group>
                    </router-link>
                </b-col>
            </b-row>
            <!-- <b-row class="mt-4">
                <b-col>
                    <h3>Worker Schedule ( Coming Soom )</h3>
                </b-col>
            </b-row> -->
            <!-- <b-row class="mt-2">
                <b-col>
                    <b-list-group>
                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                            Testing
                            <b-badge variant="primary" pill>12</b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row> -->
        </b-container>
        <b-container v-show="showRemainInfo">
            <div class="row status-info">
                <div class="col-md-12 ">
                    <table class="table table-cart" style="margin-top: 10%;">
                        <tr v-for="(remain, index) in remains">
                            <td>{{remain.name}}</td>
                            <td style="width: 100px;">
                                {{remain.qty}}
                            </td>
                        </tr>
                    </table>
                    <a class="btn btn-success mt-2 text-white" style="width: 100%;" @click="closeRemainInfo()">Close</a>
                </div>
            </div>
        </b-container>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'dashboard',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                showRemainInfo: false,
                showDashboard: true,
                dashboard: [],
                remains: [],
                completes: []
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
            ...mapActions({
                dashboardAction: 'dashboardAction',
                remainReturnItemAction: 'remainReturnItemAction',
            }),
            async dashboardDetail() {
                this.isLoading = true;
                await this.dashboardAction().then(res => {
                    if (res.status == "success") {
                        this.dashboard = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async remainInfo() {
                await this.remainReturnItemAction().then(res => {
                    if (res.status == "success") {
                        this.showDashboard = false
                        this.showRemainInfo = true
                        this.remains = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            closeRemainInfo() {
                this.showDashboard = true
                this.showRemainInfo = false
            },
        },
        async mounted() {
            this.isLoading = true
            this.dashboardDetail()
        }
    }
</script>

<style scoped lang="scss">
    .status-info {
        height: 100vh;
        background: #f8f9fa;
        z-index: 9999;
        position: absolute;
        width: 100%;
    }

    .card-scroll {
        margin-bottom: 100px;
        overflow: scroll;
    }
        /* Hide scrollbar for Chrome, Safari and Opera */
    .card-scroll::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .card-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }
</style>